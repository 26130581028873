.notification {
  display: block;
  width: 100%;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 8px;
  background: $message;
  color: #fff;

  a {
    font-weight: bold;
    text-decoration: underline;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  &.error {
    background-color: $error;
  }

  &.success {
    background-color: $success;
  }
}

.notification-toggle {
  display: block;
  width: 20px;
  height: 20px;
  float: right;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  cursor: pointer;
}