.grid-dashboard {
  > * {
    margin-top: 20px;

    &:nth-child(-n+1) {
      margin-top: 0px;
    }
  }

  @include media-query(md) {
    > * {
      &:nth-child(-n+2) {
        margin-top: 0px;
      }
    }
  }
}

.grid-downloads {
  position: relative;
  margin: 0 -20px !important;

  &:before {
    content: none;
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 50%;
    background-color: darken($lightgrey, 5%);
  }

  > * {
    padding: 0 20px !important;
  }

  @include media-query(md) {
    &:before {
      content: '';
    }
  }

  @include media-query(lg) {
    &:before {
      content: none;
    }
  }

  @include media-query(xxl) {
    &:before {
      content: '';
    }
  }
}

.grid-login {
  @include media-query(xs) {
    margin: 0px;
    align-items: center;

    > * {
      padding: 0px;
      width: auto;
    }
  }
}

.grid-data {
  > * {
    margin-top: 10px;

    &:first-child {
      margin-top: 0px;
    }
  }

  & + {
    h1, h2, h3,
    h4, h5, h6 {
      margin-top: 48px;
    }
  }
}