.card {
  display: block;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 2.5px 2.5px 5px rgba(#000, .1);
  overflow: hidden;
}

.card-article {
  position: relative;
  max-width: 960px;
}

.card-actions {
  padding: 10px;
}

.card-article-downloads {
  margin-top: 20px;
  border-left: 4px solid $red;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.card-login {
  max-width: 480px;
  margin: 0px auto;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

a.card-data {
  border-left: 4px solid transparent;
  padding-left: 2px;

  &:hover {
    border-left-color: $red;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.card-body-dashboard {
  padding: 26px;
}

.card-body-data {
  padding: 6px;

  a.card-data & {
    padding: 6px 6px 6px 2px;
  }
}

.card-body-teaser {
  padding: 36px;

  > * {
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.card-body-article {
  padding: 36px;

  > * {
    &:last-child {
      margin-bottom: 0px;
    }
  }

  @include media-query(lg) {
    padding: 64px;
  }
}

.card-body-article-downloads {
  padding-left: 32px;

  @include media-query(lg) {
    padding-left: 60px;
  }
}

.card-title {
  font-size: rem(21px);
  font-weight: normal;
  color: $blue;
  padding: 10px;
  margin: 0px;
}

.card-list {
  > ul {
    padding: 0px;
    margin: 0px;
  }

  > ul > li {
    list-style: none;
    border-top: 1px solid darken($lightgrey, 5%);
    padding: 10px 0px;

    &:first-child {
      border: 0px;
    }
  }
}

.card-list-item {
  display: flex;
  vertical-align: middle;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 10px;
  border-radius: 4px;
}

a.card-list-item {
  &:hover {
    background-color: $lightgrey;
  }
}

.card-list-item-icon {
  flex: 0 0 auto;
}

.card-list-item-description {
  margin-right: auto;
  padding: 0px 8px;

  span {
    margin-top: 4px;
    font-size: rem(13px);
    color: $mediumgrey;
  }
}

.card-list-item-actions {
  flex: 0 0 auto;
  margin-left: auto;

  > ul {
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  > ul > li {
    list-style: none;
    margin-left: 4px;

    &:first-child {
      margin-left: 0px;
    }
  }

  a {
    display: inline-block;
    padding: 5px;
    border-radius: 4px;

    &:hover {
      background-color: $lightgrey;
    }
  }
}

.card-index {
  > ul {
    padding: 0px;
    margin: 0px;
  }

  > ul > li {
    list-style: none;
  }

  a {
    display: inline-block;
    width: 100%;
    padding: 10px;
    border-radius: 4px;

    &:hover {
      background-color: $lightgrey;
    }
  }

  @include media-query(sm) {
    > ul {
      column-count: 2;
    }
  }

  @include media-query(lg) {
    > ul {
      column-count: 3;
    }
  }
}