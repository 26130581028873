.input-group {
  margin: 0px;
  padding: 10px;
}

.input-group-text-center {
  text-align: center;
}

.input-label {
  font-size: rem(13px);
  font-weight: 600;
  margin-bottom: 8px;
}

.input-text {
  height: 48px;
  background-color: transparent;
  border: 1px solid darken($lightgrey, 5%);
  border-radius: 4px;

  &:focus {
    background-color: transparent;
    border-color: $mediumgrey;
  }
}