/*
 * Molecule
 */
/*
 * Config
 */
/*
 * Rem
 */
/*
 * Media query
 */
/*
 * Visually hidden
 */
/*
 * Reset
 */
*, *:before, *:after {
  box-sizing: border-box; }

html {
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: #000;
  vertical-align: baseline; }

body {
  width: 100%;
  min-height: 100%;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #fff; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

[hidden] {
  display: none; }

h1 {
  font-size: 3rem;
  line-height: 1; }

h2 {
  font-size: 2.25rem;
  line-height: 1; }

h3 {
  font-size: 1.875rem;
  line-height: 1; }

h4 {
  font-size: 1.5rem;
  line-height: 1; }

h5 {
  font-size: 1.125rem;
  line-height: 1; }

h6 {
  font-size: 1rem; }

h1, h2, h3,
h4, h5, h6,
p, ul, ol {
  margin: 0px 0px 1rem; }

a {
  text-decoration: none;
  color: inherit; }

hr {
  border: 0px;
  border-top: 1px solid #eee; }

img,
svg {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto; }

/*
 * Container
 */
.container {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0 36px; }
  @media (min-width: 480px) {
    .container {
      max-width: 480px; } }
  @media (min-width: 640px) {
    .container {
      max-width: 640px; } }
  @media (min-width: 800px) {
    .container {
      max-width: 800px; } }
  @media (min-width: 960px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1120px) {
    .container {
      max-width: 1120px; } }
  @media (min-width: 1280px) {
    .container {
      max-width: 1280px; } }

/*
 * Text container
 */
.clear-spacing > *:last-child {
  margin-bottom: 0px; }

/*
 * Grid
 */
.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0px -12px; }

.grid-column-1 {
  width: 8.33333%;
  padding: 0px 12px; }

.grid-column-2 {
  width: 16.66667%;
  padding: 0px 12px; }

.grid-column-3 {
  width: 25%;
  padding: 0px 12px; }

.grid-column-4 {
  width: 33.33333%;
  padding: 0px 12px; }

.grid-column-5 {
  width: 41.66667%;
  padding: 0px 12px; }

.grid-column-6 {
  width: 50%;
  padding: 0px 12px; }

.grid-column-7 {
  width: 58.33333%;
  padding: 0px 12px; }

.grid-column-8 {
  width: 66.66667%;
  padding: 0px 12px; }

.grid-column-9 {
  width: 75%;
  padding: 0px 12px; }

.grid-column-10 {
  width: 83.33333%;
  padding: 0px 12px; }

.grid-column-11 {
  width: 91.66667%;
  padding: 0px 12px; }

.grid-column-12 {
  width: 100%;
  padding: 0px 12px; }

@media (min-width: 480px) {
  .grid-column-1-xs {
    width: 8.33333%;
    padding: 0px 12px; }
  .grid-column-2-xs {
    width: 16.66667%;
    padding: 0px 12px; }
  .grid-column-3-xs {
    width: 25%;
    padding: 0px 12px; }
  .grid-column-4-xs {
    width: 33.33333%;
    padding: 0px 12px; }
  .grid-column-5-xs {
    width: 41.66667%;
    padding: 0px 12px; }
  .grid-column-6-xs {
    width: 50%;
    padding: 0px 12px; }
  .grid-column-7-xs {
    width: 58.33333%;
    padding: 0px 12px; }
  .grid-column-8-xs {
    width: 66.66667%;
    padding: 0px 12px; }
  .grid-column-9-xs {
    width: 75%;
    padding: 0px 12px; }
  .grid-column-10-xs {
    width: 83.33333%;
    padding: 0px 12px; }
  .grid-column-11-xs {
    width: 91.66667%;
    padding: 0px 12px; }
  .grid-column-12-xs {
    width: 100%;
    padding: 0px 12px; } }

@media (min-width: 640px) {
  .grid-column-1-sm {
    width: 8.33333%;
    padding: 0px 12px; }
  .grid-column-2-sm {
    width: 16.66667%;
    padding: 0px 12px; }
  .grid-column-3-sm {
    width: 25%;
    padding: 0px 12px; }
  .grid-column-4-sm {
    width: 33.33333%;
    padding: 0px 12px; }
  .grid-column-5-sm {
    width: 41.66667%;
    padding: 0px 12px; }
  .grid-column-6-sm {
    width: 50%;
    padding: 0px 12px; }
  .grid-column-7-sm {
    width: 58.33333%;
    padding: 0px 12px; }
  .grid-column-8-sm {
    width: 66.66667%;
    padding: 0px 12px; }
  .grid-column-9-sm {
    width: 75%;
    padding: 0px 12px; }
  .grid-column-10-sm {
    width: 83.33333%;
    padding: 0px 12px; }
  .grid-column-11-sm {
    width: 91.66667%;
    padding: 0px 12px; }
  .grid-column-12-sm {
    width: 100%;
    padding: 0px 12px; } }

@media (min-width: 800px) {
  .grid-column-1-md {
    width: 8.33333%;
    padding: 0px 12px; }
  .grid-column-2-md {
    width: 16.66667%;
    padding: 0px 12px; }
  .grid-column-3-md {
    width: 25%;
    padding: 0px 12px; }
  .grid-column-4-md {
    width: 33.33333%;
    padding: 0px 12px; }
  .grid-column-5-md {
    width: 41.66667%;
    padding: 0px 12px; }
  .grid-column-6-md {
    width: 50%;
    padding: 0px 12px; }
  .grid-column-7-md {
    width: 58.33333%;
    padding: 0px 12px; }
  .grid-column-8-md {
    width: 66.66667%;
    padding: 0px 12px; }
  .grid-column-9-md {
    width: 75%;
    padding: 0px 12px; }
  .grid-column-10-md {
    width: 83.33333%;
    padding: 0px 12px; }
  .grid-column-11-md {
    width: 91.66667%;
    padding: 0px 12px; }
  .grid-column-12-md {
    width: 100%;
    padding: 0px 12px; } }

@media (min-width: 960px) {
  .grid-column-1-lg {
    width: 8.33333%;
    padding: 0px 12px; }
  .grid-column-2-lg {
    width: 16.66667%;
    padding: 0px 12px; }
  .grid-column-3-lg {
    width: 25%;
    padding: 0px 12px; }
  .grid-column-4-lg {
    width: 33.33333%;
    padding: 0px 12px; }
  .grid-column-5-lg {
    width: 41.66667%;
    padding: 0px 12px; }
  .grid-column-6-lg {
    width: 50%;
    padding: 0px 12px; }
  .grid-column-7-lg {
    width: 58.33333%;
    padding: 0px 12px; }
  .grid-column-8-lg {
    width: 66.66667%;
    padding: 0px 12px; }
  .grid-column-9-lg {
    width: 75%;
    padding: 0px 12px; }
  .grid-column-10-lg {
    width: 83.33333%;
    padding: 0px 12px; }
  .grid-column-11-lg {
    width: 91.66667%;
    padding: 0px 12px; }
  .grid-column-12-lg {
    width: 100%;
    padding: 0px 12px; } }

@media (min-width: 1120px) {
  .grid-column-1-xl {
    width: 8.33333%;
    padding: 0px 12px; }
  .grid-column-2-xl {
    width: 16.66667%;
    padding: 0px 12px; }
  .grid-column-3-xl {
    width: 25%;
    padding: 0px 12px; }
  .grid-column-4-xl {
    width: 33.33333%;
    padding: 0px 12px; }
  .grid-column-5-xl {
    width: 41.66667%;
    padding: 0px 12px; }
  .grid-column-6-xl {
    width: 50%;
    padding: 0px 12px; }
  .grid-column-7-xl {
    width: 58.33333%;
    padding: 0px 12px; }
  .grid-column-8-xl {
    width: 66.66667%;
    padding: 0px 12px; }
  .grid-column-9-xl {
    width: 75%;
    padding: 0px 12px; }
  .grid-column-10-xl {
    width: 83.33333%;
    padding: 0px 12px; }
  .grid-column-11-xl {
    width: 91.66667%;
    padding: 0px 12px; }
  .grid-column-12-xl {
    width: 100%;
    padding: 0px 12px; } }

@media (min-width: 1280px) {
  .grid-column-1-xxl {
    width: 8.33333%;
    padding: 0px 12px; }
  .grid-column-2-xxl {
    width: 16.66667%;
    padding: 0px 12px; }
  .grid-column-3-xxl {
    width: 25%;
    padding: 0px 12px; }
  .grid-column-4-xxl {
    width: 33.33333%;
    padding: 0px 12px; }
  .grid-column-5-xxl {
    width: 41.66667%;
    padding: 0px 12px; }
  .grid-column-6-xxl {
    width: 50%;
    padding: 0px 12px; }
  .grid-column-7-xxl {
    width: 58.33333%;
    padding: 0px 12px; }
  .grid-column-8-xxl {
    width: 66.66667%;
    padding: 0px 12px; }
  .grid-column-9-xxl {
    width: 75%;
    padding: 0px 12px; }
  .grid-column-10-xxl {
    width: 83.33333%;
    padding: 0px 12px; }
  .grid-column-11-xxl {
    width: 91.66667%;
    padding: 0px 12px; }
  .grid-column-12-xxl {
    width: 100%;
    padding: 0px 12px; } }

.input-group {
  margin: 0px 0px 2rem; }
  .input-group.is-floating {
    position: relative; }

.input-label {
  display: block;
  font-weight: bold;
  margin: 0px 0px .75rem; }
  .input-label:hover {
    cursor: pointer; }
  .has-error .input-label {
    color: #fa7e63; }
  .has-floating-labels .input-label {
    display: none;
    vertical-align: middle;
    height: 18px;
    padding: 0px 4px;
    border-radius: 2px;
    position: absolute;
    top: -9px;
    left: 16px;
    z-index: 1;
    background-color: #1c1c1c;
    font-size: 0.75rem;
    line-height: 18px;
    color: #fff; }
  .has-floating-labels.is-floating .input-label {
    display: inline-block; }

.input-label-note {
  font-size: 0.75rem;
  font-weight: normal;
  text-transform: lowercase;
  color: #999; }
  .has-floating-labels .input-label-note {
    color: #ccc; }
  .input-label-note:before {
    content: '['; }
  .input-label-note:after {
    content: ']'; }

.input-icon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  fill: #666;
  pointer-events: none; }

.input-text {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: 64px;
  padding: 8px 16px;
  font-size: 1rem;
  background-color: #f3f3f3;
  border: 2px solid transparent;
  border-radius: 4px;
  flex: 1 0 auto;
  order: 2; }
  .has-error .input-text {
    border-color: #fa7e63; }
  .input-text.has-prepend {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }
    .has-error .input-text.has-prepend {
      border-left: 0px; }
  .input-text.has-append {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; }
    .has-error .input-text.has-append {
      border-right: 0px; }
  .input-text:focus {
    outline: 0px;
    background-color: #e3e3e3; }

.input-text-prepend {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 8px 0px 8px 16px;
  border: 2px solid transparent;
  border-right: 0px;
  order: 1;
  height: 64px;
  background-color: #f3f3f3;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }
  .has-error .input-text-prepend {
    border-color: #fa7e63; }
  .input-text:focus ~ .input-text-prepend {
    background-color: #e3e3e3; }

.input-text-append {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 0px;
  border: 2px solid transparent;
  border-left: 0px;
  order: 3;
  height: 64px;
  background-color: #f3f3f3;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }
  .has-error .input-text-append {
    border-color: #fa7e63; }
  .input-text:focus ~ .input-text-append {
    background-color: #e3e3e3; }

.input-wrapper-text {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative; }

.input-select {
  appearance: none;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 8px 16px;
  font-size: 1rem;
  background-color: #f3f3f3;
  border: 2px solid transparent;
  border-radius: 4px; }
  .input-select:hover {
    cursor: pointer; }
  .input-select:focus {
    outline: 0px;
    background-color: #e3e3e3; }
  .input-select::-ms-expand {
    display: none; }
  .has-error .input-select {
    border-color: #fa7e63; }
  .input-icon-sm + .input-select {
    padding: 8px 40px 8px 16px; }
  .input-icon-lg + .input-select {
    padding: 8px 48px 8px 16px; }

.input-wrapper-select {
  position: relative; }

.input-textarea {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 16px;
  font-size: 1rem;
  background-color: #f3f3f3;
  border: 2px solid transparent;
  border-radius: 4px; }
  .input-textarea:focus {
    outline: 0px;
    background-color: #e3e3e3; }
  .has-error .input-textarea {
    border-color: #fa7e63; }

.input-radio {
  position: relative;
  display: inline-block;
  vertical-align: middle; }
  .input-radio:hover {
    cursor: pointer; }

.input-list-radio {
  padding: 0px;
  margin: 0px; }
  .input-list-radio li {
    list-style: none;
    margin-bottom: 4px; }
    .input-list-radio li:last-child {
      margin-bottom: 0px; }

.input-radio-input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.input-radio-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 10px; }
  .input-radio-icon:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #666; }
  .input-radio-input:checked + .input-radio-icon:before {
    display: block; }
  .input-radio-input:focus + .input-radio-icon {
    border-color: #999; }

.input-radio-label {
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px; }

.input-checkbox {
  position: relative;
  display: inline-block;
  vertical-align: middle; }
  .input-checkbox:hover {
    cursor: pointer; }

.input-list-checkbox {
  padding: 0px;
  margin: 0px; }
  .input-list-checkbox li {
    list-style: none;
    margin-bottom: 4px; }
    .input-list-checkbox li:last-child {
      margin-bottom: 0px; }

.input-checkbox-input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.input-checkbox-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px; }
  .input-checkbox-icon:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    display: none;
    width: 7px;
    height: 12px;
    border: solid #666;
    border-width: 0 3px 3px 0;
    margin-top: -1px; }
  .input-checkbox-input:checked + .input-checkbox-icon:before {
    display: block; }
  .input-checkbox-input:focus + .input-checkbox-icon {
    border-color: #999; }

.input-checkbox-label {
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px; }

.button {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  min-width: 180px;
  padding: 8px 16px;
  border: 0px;
  background-color: #1c1c1c;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border-radius: 4px; }
  .button:focus {
    outline: 0px;
    background-color: #2c2c2c; }
  .button:hover {
    background-color: #2c2c2c;
    cursor: pointer; }

/*
 * Icons
 */
.icon-xs {
  fill: #999;
  width: 10px;
  height: 10px; }

.icon-sm {
  fill: #999;
  width: 14px;
  height: 14px; }

.icon-md {
  fill: #999;
  width: 20px;
  height: 20px; }

.icon-lg {
  fill: #999;
  width: 24px;
  height: 24px; }

.icon-xl {
  fill: #999;
  width: 32px;
  height: 32px; }

.icon-xxl {
  fill: #999;
  width: 48px;
  height: 48px; }

/*
 * Theme
 */
/*
 * Config
 */
/*
 * Web
 */
html {
  width: 100%;
  height: 100%; }

body {
  width: 100%;
  height: 100%;
  background: #ecf6fd;
  overflow: hidden;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9375rem;
  line-height: 1.2; }

h1, h2, h3,
h4, h5, h6 {
  font-weight: normal;
  line-height: 1.2;
  color: #338fd2; }

h1 {
  font-size: 1.8125rem; }

h2 {
  font-size: 1.3125rem; }

p {
  line-height: 1.5;
  margin-bottom: 24px; }
  p + h1, p + h2, p + h3,
  p + h4, p + h5, p + h6 {
    margin-top: 48px; }

p.intro {
  font-size: 1.0625rem; }

p.image {
  margin: 48px 0px; }

.page-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden; }

.page-top {
  flex: 0 0 auto; }

.page-bottom {
  position: relative;
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  overflow: hidden; }
  @media (min-width: 960px) {
    .page-bottom {
      flex-direction: row; } }

.page-sidebar {
  display: none;
  width: 100%;
  max-height: 100%;
  max-width: 320px; }
  @media (min-width: 960px) {
    .page-sidebar {
      display: flex; } }

.page-main {
  width: 100%;
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.page-main-wrapper {
  margin: 36px 24px; }
  @media (min-width: 960px) {
    .page-main-wrapper {
      margin: 64px 48px; } }

.page-title {
  font-size: 1.5625rem;
  font-weight: 600;
  color: #3A3A3A;
  margin-bottom: 24px; }

.page-notifications {
  margin-bottom: 24px; }

.page-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
  max-width: 960px; }

.page-mobile-nav {
  position: relative;
  z-index: 3;
  flex: 0 0 auto;
  width: 100%; }
  @media (min-width: 960px) {
    .page-mobile-nav {
      display: none; } }

.page-watch-downloads {
  display: inline-block;
  vertical-align: middle;
  margin-left: auto;
  padding: 10px;
  border-radius: 4px; }
  .page-watch-downloads:hover {
    background-color: #fff; }
  .page-watch-downloads + .page-add-favorite {
    margin-left: 2px; }

.page-watch-downloads-icon {
  margin-left: 8px; }

.page-add-favorite {
  display: inline-block;
  vertical-align: middle;
  margin-left: auto;
  padding: 10px;
  border-radius: 4px; }
  .page-add-favorite:hover, .page-add-favorite.is-active {
    background-color: #fff; }

.container {
  max-width: 1280px !important;
  margin: 0px;
  padding: 0px; }

.header {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 8px;
  background-color: #fff;
  box-shadow: 2.5px 2.5px 5px rgba(0, 0, 0, 0.1); }
  @media (min-width: 960px) {
    .header {
      padding: 26px; } }

.sidebar {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 26px;
  border-top: 1px solid #d5ebfb;
  background-color: #fff;
  box-shadow: 2.5px 2.5px 5px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.section {
  padding: 48px 0px; }

.nav-profile {
  position: relative;
  margin-left: auto; }

.nav-profile-toggle {
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  border-radius: 4px; }
  .nav-profile-toggle:hover {
    background-color: #ecf6fd; }

.nav-profile-toggle-label {
  display: none;
  padding: 0px 4px 0px 8px; }
  @media (min-width: 960px) {
    .nav-profile-toggle-label {
      display: inline-block; } }

.nav-profile-panel {
  display: none;
  position: absolute;
  top: calc(100% + 16px);
  right: 0px;
  background-color: #fff;
  box-shadow: 2.5px 2.5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #d5ebfb; }
  .nav-profile-panel.is-toggled {
    display: block; }
  .nav-profile-panel ul {
    padding: 0px;
    margin: 0px; }
  .nav-profile-panel li {
    list-style: none; }
  .nav-profile-panel a {
    display: block;
    min-width: 200px;
    padding: 10px;
    border-radius: 4px;
    white-space: nowrap; }
    .nav-profile-panel a:hover {
      background-color: #ecf6fd; }

.nav-sidebar {
  margin-top: 48px; }
  .nav-sidebar:first-child {
    margin-top: 0px; }

.nav-sidebar-title {
  font-size: 0.6875rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .1em;
  color: #819296;
  margin: 0px;
  padding: 10px 10px 14px; }

.nav-sidebar-icon {
  position: relative; }
  .nav-sidebar-icon.ap {
    margin-left: 4px; }
  .nav-sidebar-icon.pre {
    margin-right: 8px; }

.nav-sidebar-alert {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-color: #c92029;
  border-radius: 8px;
  font-size: 0.625rem;
  line-height: 16px;
  text-align: center;
  color: #fff;
  margin-left: 4px; }

.nav-sidebar-count {
  font-size: 0.8125rem;
  font-weight: normal;
  color: #819296; }

.nav-sidebar-panel ul {
  padding: 0px;
  margin: 0px; }

.nav-sidebar-panel li {
  list-style: none; }
  .nav-sidebar-panel li[data-toggle-id] {
    display: none; }
    .nav-sidebar-panel li[data-toggle-id].is-toggled {
      display: block; }

.nav-sidebar-panel a {
  display: block;
  padding: 10px;
  border-radius: 4px; }
  .nav-sidebar-panel a:hover {
    background-color: #ecf6fd; }
  .nav-sidebar-panel a.is-active {
    font-weight: 600;
    color: #c92029; }

.nav-sidebar-more {
  font-weight: 600;
  color: #c92029; }
  .nav-sidebar-more.is-toggled .nav-sidebar-icon {
    transform: rotate(180deg); }
  .nav-sidebar-more.is-toggled:hover .nav-sidebar-icon {
    animation: bounceUp 2s infinite !important; }
  .nav-sidebar-panel .nav-sidebar-more {
    display: inline-block; }
    .nav-sidebar-panel .nav-sidebar-more:hover {
      background-color: transparent; }
      .nav-sidebar-panel .nav-sidebar-more:hover .nav-sidebar-icon {
        animation: bounceDown 2s infinite; }

.nav-mobile {
  padding: 16px;
  background-color: #fff;
  box-shadow: 2.5px -2.5px 5px rgba(0, 0, 0, 0.1); }

.nav-mobile-panel > ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center; }

.nav-mobile-panel > ul > li {
  list-style: none;
  position: relative; }

.nav-mobile-alert {
  position: absolute;
  top: -4px;
  right: -10px;
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-color: #c92029;
  border-radius: 8px;
  font-size: 0.625rem;
  line-height: 16px;
  text-align: center;
  color: #fff;
  margin-left: 4px; }

.nav-breadcrumb {
  display: none;
  margin-right: auto; }
  @media (min-width: 960px) {
    .nav-breadcrumb {
      display: block; } }

.nav-breadcrumb-panel ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px;
  margin: 0px; }

.nav-breadcrumb-panel li {
  list-style: none; }
  .nav-breadcrumb-panel li:before {
    display: inline-block;
    content: '>';
    margin: 0 8px;
    color: #819296; }
  .nav-breadcrumb-panel li:first-child:before {
    content: none; }

.nav-breadcrumb-panel a {
  text-decoration: underline;
  color: #819296; }
  .nav-breadcrumb-panel a:hover {
    text-decoration: none; }
  .nav-breadcrumb-panel a.is-active {
    text-decoration: none;
    font-weight: 600;
    color: #3A3A3A; }

.nav-pag {
  width: 100%;
  margin-top: 24px; }

.nav-pag-panel > ul {
  padding: 0px;
  margin: 0px;
  display: flex; }

.nav-pag-panel > ul > li {
  list-style: none;
  margin: 0px 1px;
  display: none; }
  @media (min-width: 480px) {
    .nav-pag-panel > ul > li {
      display: block; } }
  .nav-pag-panel > ul > li:first-child {
    margin-right: auto;
    margin-left: 0px;
    display: block; }
  .nav-pag-panel > ul > li:last-child {
    margin-left: auto;
    margin-right: 0px;
    display: block; }

.nav-pag-panel a,
.nav-pag-panel span {
  display: inline-block;
  padding: 10px;
  min-width: 38px;
  border-radius: 4px;
  text-align: center; }
  .nav-pag-panel a:hover, .nav-pag-panel a.is-active,
  .nav-pag-panel span:hover,
  .nav-pag-panel span.is-active {
    background-color: #fff; }
  .nav-pag-panel a.is-active,
  .nav-pag-panel span.is-active {
    font-weight: 600;
    color: #c92029; }

.nav-pag-panel span {
  color: #819296; }
  .nav-pag-panel span:hover {
    background-color: transparent; }

@keyframes bounceUp {
  0%, 20%, 50%, 80%, 100% {
    top: 0px; }
  40% {
    top: -6px; }
  60% {
    top: -2px; } }

@keyframes bounceDown {
  0%, 20%, 50%, 80%, 100% {
    bottom: 0px; }
  40% {
    bottom: -6px; }
  60% {
    bottom: -2px; } }

.logo-link {
  display: inline-block;
  vertical-align: middle;
  margin-right: auto; }

.logo {
  width: 170px;
  height: 78px;
  padding: 10px; }
  @media (min-width: 800px) {
    .logo {
      padding: 0px; } }

.icon-blue {
  fill: #338fd2; }

.icon-red {
  fill: #c92029; }

.icon-white {
  fill: #fff; }

.icon-mediumgrey {
  fill: #819296; }

.icon-darkgrey {
  fill: #3A3A3A; }

.card {
  display: block;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 2.5px 2.5px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden; }

.card-article {
  position: relative;
  max-width: 960px; }

.card-actions {
  padding: 10px; }

.card-article-downloads {
  margin-top: 20px;
  border-left: 4px solid #c92029;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px; }

.card-login {
  max-width: 480px;
  margin: 0px auto; }
  .card-login a {
    text-decoration: underline; }
    .card-login a:hover {
      text-decoration: none; }

a.card-data {
  border-left: 4px solid transparent;
  padding-left: 2px; }
  a.card-data:hover {
    border-left-color: #c92029;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }

.card-body-dashboard {
  padding: 26px; }

.card-body-data {
  padding: 6px; }
  a.card-data .card-body-data {
    padding: 6px 6px 6px 2px; }

.card-body-teaser {
  padding: 36px; }
  .card-body-teaser > *:last-child {
    margin-bottom: 0px; }

.card-body-article {
  padding: 36px; }
  .card-body-article > *:last-child {
    margin-bottom: 0px; }
  @media (min-width: 960px) {
    .card-body-article {
      padding: 64px; } }

.card-body-article-downloads {
  padding-left: 32px; }
  @media (min-width: 960px) {
    .card-body-article-downloads {
      padding-left: 60px; } }

.card-title {
  font-size: 1.3125rem;
  font-weight: normal;
  color: #338fd2;
  padding: 10px;
  margin: 0px; }

.card-list > ul {
  padding: 0px;
  margin: 0px; }

.card-list > ul > li {
  list-style: none;
  border-top: 1px solid #d5ebfb;
  padding: 10px 0px; }
  .card-list > ul > li:first-child {
    border: 0px; }

.card-list-item {
  display: flex;
  vertical-align: middle;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 10px;
  border-radius: 4px; }

a.card-list-item:hover {
  background-color: #ecf6fd; }

.card-list-item-icon {
  flex: 0 0 auto; }

.card-list-item-description {
  margin-right: auto;
  padding: 0px 8px; }
  .card-list-item-description span {
    margin-top: 4px;
    font-size: 0.8125rem;
    color: #819296; }

.card-list-item-actions {
  flex: 0 0 auto;
  margin-left: auto; }
  .card-list-item-actions > ul {
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; }
  .card-list-item-actions > ul > li {
    list-style: none;
    margin-left: 4px; }
    .card-list-item-actions > ul > li:first-child {
      margin-left: 0px; }
  .card-list-item-actions a {
    display: inline-block;
    padding: 5px;
    border-radius: 4px; }
    .card-list-item-actions a:hover {
      background-color: #ecf6fd; }

.card-index > ul {
  padding: 0px;
  margin: 0px; }

.card-index > ul > li {
  list-style: none; }

.card-index a {
  display: inline-block;
  width: 100%;
  padding: 10px;
  border-radius: 4px; }
  .card-index a:hover {
    background-color: #ecf6fd; }

@media (min-width: 640px) {
  .card-index > ul {
    column-count: 2; } }

@media (min-width: 960px) {
  .card-index > ul {
    column-count: 3; } }

.grid-dashboard > * {
  margin-top: 20px; }
  .grid-dashboard > *:nth-child(-n+1) {
    margin-top: 0px; }

@media (min-width: 800px) {
  .grid-dashboard > *:nth-child(-n+2) {
    margin-top: 0px; } }

.grid-downloads {
  position: relative;
  margin: 0 -20px !important; }
  .grid-downloads:before {
    content: none;
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 50%;
    background-color: #d5ebfb; }
  .grid-downloads > * {
    padding: 0 20px !important; }
  @media (min-width: 800px) {
    .grid-downloads:before {
      content: ''; } }
  @media (min-width: 960px) {
    .grid-downloads:before {
      content: none; } }
  @media (min-width: 1280px) {
    .grid-downloads:before {
      content: ''; } }

@media (min-width: 480px) {
  .grid-login {
    margin: 0px;
    align-items: center; }
    .grid-login > * {
      padding: 0px;
      width: auto; } }

.grid-data > * {
  margin-top: 10px; }
  .grid-data > *:first-child {
    margin-top: 0px; }

.grid-data + h1, .grid-data + h2, .grid-data + h3,
.grid-data + h4, .grid-data + h5, .grid-data + h6 {
  margin-top: 48px; }

.input-group {
  margin: 0px;
  padding: 10px; }

.input-group-text-center {
  text-align: center; }

.input-label {
  font-size: 0.8125rem;
  font-weight: 600;
  margin-bottom: 8px; }

.input-text {
  height: 48px;
  background-color: transparent;
  border: 1px solid #d5ebfb;
  border-radius: 4px; }
  .input-text:focus {
    background-color: transparent;
    border-color: #819296; }

.button {
  font-weight: normal;
  width: 100%;
  padding: 8px 36px; }
  @media (min-width: 480px) {
    .button {
      width: auto; } }

.button-red {
  background-color: #c92029; }
  .button-red:hover {
    background-color: #b31c25; }

.notification {
  display: block;
  width: 100%;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 8px;
  background: #338fd2;
  color: #fff; }
  .notification a {
    font-weight: bold;
    text-decoration: underline; }
  .notification:last-child {
    margin-bottom: 0px; }
  .notification.error {
    background-color: #c92029; }
  .notification.success {
    background-color: #32aba0; }

.notification-toggle {
  display: block;
  width: 20px;
  height: 20px;
  float: right;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  cursor: pointer; }

/*
 * Print
 */
