.header {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 8px;
  background-color: #fff;
  box-shadow: 2.5px 2.5px 5px rgba(#000, .1);

  @include media-query(lg) {
    padding: 26px;
  }
}