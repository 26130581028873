/*
 * Icons
 */

.icon {
  @each $size, $value in map-keys($icon-sizes) {
    &-#{$size} {
      fill: #999;
      width: map-get($icon-sizes, $size);
      height: map-get($icon-sizes, $size);
    }
  }
}