/*
 * Reset
 */

*, *:before, *:after {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  color: $base-font-color;
  vertical-align: baseline;
}

body {
  width: 100%;
  min-height: 100%;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  font-size: 1rem;
  line-height: 1.5;
  background-color: $base-background-color;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

[hidden] {
  display: none;
}

h1 {
  font-size: rem(map-get($headings, h1));
  line-height: 1;
}

h2 {
  font-size: rem(map-get($headings, h2));
  line-height: 1;
}

h3 {
  font-size: rem(map-get($headings, h3));
  line-height: 1;
}

h4 {
  font-size: rem(map-get($headings, h4));
  line-height: 1;
}

h5 {
  font-size: rem(map-get($headings, h5));
  line-height: 1;
}

h6 {
  font-size: rem(map-get($headings, h6));
}

h1, h2, h3,
h4, h5, h6,
p, ul, ol {
  margin: 0px 0px 1rem;
}

a {
  text-decoration: none;
  color: inherit;
}

hr {
  border: 0px;
  border-top: 1px solid #eee;
}

img,
svg {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}