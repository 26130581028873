.nav-profile {
  position: relative;
  margin-left: auto;
}

.nav-profile-toggle {
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  border-radius: 4px;

  &:hover {
    background-color: $lightgrey;
  }
}

.nav-profile-toggle-label {
  display: none;
  padding: 0px 4px 0px 8px;

  @include media-query(lg) {
    display: inline-block;
  }
}

.nav-profile-panel {
  display: none;
  position: absolute;
  top: calc(100% + 16px);
  right: 0px;
  background-color: #fff;
  box-shadow: 2.5px 2.5px 5px rgba(#000, .1);
  border-radius: 4px;
  padding: 10px;
  border: 1px solid darken($lightgrey, 5%);

  &.is-toggled {
    display: block;
  }

  ul {
    padding: 0px;
    margin: 0px;
  }

  li {
    list-style: none;
  }

  a {
    display: block;
    min-width: 200px;
    padding: 10px;
    border-radius: 4px;
    white-space: nowrap;

    &:hover {
      background-color: $lightgrey;
    }
  }
}

.nav-sidebar {
  margin-top: 48px;

  &:first-child {
    margin-top: 0px;
  }
}

.nav-sidebar-title {
  font-size: rem(11px);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .1em;
  color: $mediumgrey;
  margin: 0px;
  padding: 10px 10px 14px;
}

.nav-sidebar-icon {
  position: relative;

  &.ap {
    margin-left: 4px;
  }

  &.pre {
    margin-right: 8px;
  }
}

.nav-sidebar-alert {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-color: $red;
  border-radius: 8px;
  font-size: rem(10px);
  line-height: 16px;
  text-align: center;
  color: #fff;
  margin-left: 4px;
}

.nav-sidebar-count {
  font-size: rem(13px);
  font-weight: normal;
  color: $mediumgrey;
}

.nav-sidebar-panel {
  ul {
    padding: 0px;
    margin: 0px;
  }

  li {
    list-style: none;

    &[data-toggle-id] {
      display: none;

      &.is-toggled {
        display: block;
      }
    }
  }

  a {
    display: block;
    padding: 10px;
    border-radius: 4px;

    &:hover {
      background-color: $lightgrey;
    }

    &.is-active {
      font-weight: 600;
      color: $red;
    }
  }
}

.nav-sidebar-more {
  font-weight: 600;
  color: $red;

  &.is-toggled {
    .nav-sidebar-icon {
      transform: rotate(180deg);
    }

    &:hover {
      .nav-sidebar-icon {
        animation: bounceUp 2s infinite !important;
      }
    }
  }

  .nav-sidebar-panel & {
    display: inline-block;

    &:hover {
      background-color: transparent;

      .nav-sidebar-icon {
        animation: bounceDown 2s infinite;
      }
    }
  }
}

.nav-mobile {
  padding: 16px;
  background-color: #fff;
  box-shadow: 2.5px -2.5px 5px rgba(#000, .1);
}

.nav-mobile-panel {
 > ul {
   padding: 0px;
   margin: 0px;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: space-around;
   align-items: center;
 }

 > ul > li {
   list-style: none;
   position: relative;
 }
}

.nav-mobile-alert {
  position: absolute;
  top: -4px;
  right: -10px;
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-color: $red;
  border-radius: 8px;
  font-size: rem(10px);
  line-height: 16px;
  text-align: center;
  color: #fff;
  margin-left: 4px;
}

.nav-breadcrumb {
  display: none;
  margin-right: auto;

  @include media-query(lg) {
    display: block;
  }
}

.nav-breadcrumb-panel {
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px;
    margin: 0px;
  }

  li {
    list-style: none;

    &:before {
      display: inline-block;
      content: '>';
      margin: 0 8px;
      color: $mediumgrey;
    }

    &:first-child {
      &:before {
        content: none;
      }
    }
  }

  a {
    text-decoration: underline;
    color: $mediumgrey;

    &:hover {
      text-decoration: none;
    }

    &.is-active {
      text-decoration: none;
      font-weight: 600;
      color: $darkgrey;
    }
  }
}

.nav-pag {
  width: 100%;
  margin-top: 24px;
}

.nav-pag-panel {
  > ul {
    padding: 0px;
    margin: 0px;
    display: flex;
  }

  > ul > li {
    list-style: none;
    margin: 0px 1px;
    display: none;

    @include media-query(xs) {
      display: block;
    }

    &:first-child {
      margin-right: auto;
      margin-left: 0px;
      display: block;
    }

    &:last-child {
      margin-left: auto;
      margin-right: 0px;
      display: block;
    }
  }

  a,
  span {
    display: inline-block;
    padding: 10px;
    min-width: 38px;
    border-radius: 4px;
    text-align: center;

    &:hover,
    &.is-active {
      background-color: #fff;
    }

    &.is-active {
      font-weight: 600;
      color: $red;
    }
  }

  span {
    color: $mediumgrey;
  
    &:hover {
      background-color: transparent; 
    }
  }
}

@keyframes bounceUp {
	0%, 20%, 50%, 80%, 100% {
    top: 0px;
  }
	40% {
    top: -6px;
  }
	60% {
    top: -2px;
  }
}

@keyframes bounceDown {
	0%, 20%, 50%, 80%, 100% {
    bottom: 0px;
  }
	40% {
    bottom: -6px;
  }
	60% {
    bottom: -2px;
  }
}