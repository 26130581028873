/*
 * Media query
 */

@mixin media-query($breakpoint, $query1: min, $query2: width) {
  $query: map-get($grid-breakpoints, $breakpoint);
  
  @if $query != null {
    @media (#{$query1}-#{$query2}: #{$query}) {
      @content;
    }
  } @else {
    @media (#{$query1}-#{$query2}: $breakpoint) {
      @content;
    }
  }
}