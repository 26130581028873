.button {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  min-width: 180px;
  padding: 8px 16px;
  border: 0px;
  background-color: #1c1c1c;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border-radius: 4px;

  &:focus {
    outline: 0px;
    background-color: #2c2c2c;
  }

  &:hover {
    background-color: #2c2c2c;
    cursor: pointer;
  }
}