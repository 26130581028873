.sidebar {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 26px;
  border-top: 1px solid darken($lightgrey, 5%);
  background-color: #fff;
  box-shadow: 2.5px 2.5px 5px rgba(#000, .1);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}