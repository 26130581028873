html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  background: $lightgrey;
  overflow: hidden;
  font-family: 'Open Sans', sans-serif;
  font-size: rem(15px);
  line-height: 1.2;
}

h1, h2, h3,
h4, h5, h6 {
  font-weight: normal;
  line-height: 1.2;
  color: $blue;
}

h1 {
  font-size: rem(29px);
}

h2 {
  font-size: rem(21px);
}

p {
  line-height: 1.5;
  margin-bottom: 24px;

  & + {
    h1, h2, h3,
    h4, h5, h6 {
      margin-top: 48px;
    }
  }
}

p.intro {
  font-size: rem(17px);
}

p.image {
  margin: 48px 0px;
}

.page-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
}

.page-top {
  flex: 0 0 auto;
}

.page-bottom {
  position: relative;
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  overflow: hidden;

  @include media-query(lg) {
    flex-direction: row;
  }
}

.page-sidebar {
  display: none;
  width: 100%;
  max-height: 100%;
  max-width: 320px;

  @include media-query(lg) {
    display: flex;
  }
}

.page-main {
  width: 100%;
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.page-main-wrapper {
  margin: 36px 24px;

  @include media-query(lg) {
    margin: 64px 48px;
  }
}

.page-title {
  font-size: rem(25px);
  font-weight: 600;
  color: $darkgrey;
  margin-bottom: 24px;
}

.page-notifications {
  margin-bottom: 24px;
}

.page-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
  max-width: 960px;
}

.page-mobile-nav {
  position: relative;
  z-index: 3;
  flex: 0 0 auto;
  width: 100%;

  @include media-query(lg) {
    display: none;
  }
}

.page-watch-downloads {
  display: inline-block;
  vertical-align: middle;
  margin-left: auto;
  padding: 10px;
  border-radius: 4px;

  &:hover {
    background-color: #fff;
  }

  & + .page-add-favorite {
    margin-left: 2px;
  }
}

.page-watch-downloads-icon {
  margin-left: 8px;
}

.page-add-favorite {
  display: inline-block;
  vertical-align: middle;
  margin-left: auto;
  padding: 10px;
  border-radius: 4px;

  &:hover,
  &.is-active {
    background-color: #fff;
  }
}

.container {
  max-width: map-get($grid-breakpoints, xxl) !important;
  margin: 0px;
  padding: 0px;
}