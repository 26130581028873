/*
 * Config
 */

 $red: #c92029;
 $blue: #338fd2;
 $lightgrey: #ecf6fd;
 $mediumgrey: #819296;
 $darkgrey: #3A3A3A;
 $message: $blue;
 $error: $red;
 $success: #32aba0;