.icon-blue {
  fill: $blue;
}

.icon-red {
  fill: $red;
}

.icon-white {
  fill: #fff;
}

.icon-mediumgrey {
  fill: $mediumgrey;
}

.icon-darkgrey {
  fill: $darkgrey;
}