.logo-link {
  display: inline-block;
  vertical-align: middle;
  margin-right: auto;
}

.logo {
  width: 170px;
  height: 78px;
  padding: 10px;
  
  @include media-query(md) {
  	padding: 0px;
  }
}