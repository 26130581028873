.input-group {
  margin: 0px 0px 2rem;

  &.is-floating {
    position: relative;
  }
}

.input-label {
  display: block;
  font-weight: bold;
  margin: 0px 0px .75rem;

  &:hover {
    cursor: pointer;
  }

  .has-error & {
    color: $color-error;
  }

  .has-floating-labels & {
    display: none;
    vertical-align: middle;
    height: 18px;
    padding: 0px 4px;
    border-radius: 2px;
    position: absolute;
    top: -9px;
    left: 16px;
    z-index: 1;
    background-color: #1c1c1c;
    font-size: rem(12px);
    line-height: 18px;
    color: #fff;
  }

  .has-floating-labels.is-floating & {
    display: inline-block;
  }
}

.input-label-note {
  font-size: rem(12px);
  font-weight: normal;
  text-transform: lowercase;
  color: #999;

  .has-floating-labels & {
    color: #ccc;
  }

  &:before {
    content: '[';
  }

  &:after {
    content: ']';
  }
}

.input-icon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  fill: #666;
  pointer-events: none;
}

.input-text {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: 64px;
  padding: 8px 16px;
  font-size: 1rem;
  background-color: #f3f3f3;
  border: 2px solid transparent;
  border-radius: 4px;
  flex: 1 0 auto;
  order: 2;

  .has-error & {
    border-color: $color-error;
  }

  &.has-prepend {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    .has-error & {
      border-left: 0px;
    }
  }

  &.has-append {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    .has-error & {
      border-right: 0px;
    }
  }

  &:focus {
    outline: 0px;
    background-color: #e3e3e3;
  }
}

.input-text-prepend {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 8px 0px 8px 16px;
  border: 2px solid transparent;
  border-right: 0px;
  order: 1;
  height: 64px;
  background-color: #f3f3f3;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  .has-error & {
    border-color: $color-error;
  }

  .input-text:focus ~ & {
    background-color: #e3e3e3;
  }
}

.input-text-append {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 0px;
  border: 2px solid transparent;
  border-left: 0px;
  order: 3;
  height: 64px;
  background-color: #f3f3f3;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  .has-error & {
    border-color: $color-error;
  }

  .input-text:focus ~ & {
    background-color: #e3e3e3;
  }
}

.input-wrapper-text {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
}

.input-select {
  appearance: none;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 8px 16px;
  font-size: 1rem;
  background-color: #f3f3f3;
  border: 2px solid transparent;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: 0px;
    background-color: #e3e3e3;
  }

  &::-ms-expand {
    display: none;
  }

  .has-error & {
    border-color: $color-error;
  }

  .input-icon-sm + & {
    padding: 8px 40px 8px 16px;
  }

  .input-icon-lg + & {
    padding: 8px 48px 8px 16px;
  }
}

.input-wrapper-select {
  position: relative;
}

.input-textarea {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 16px;
  font-size: 1rem;
  background-color: #f3f3f3;
  border: 2px solid transparent;
  border-radius: 4px;

  &:focus {
    outline: 0px;
    background-color: #e3e3e3;
  }

  .has-error & {
    border-color: $color-error;
  }
}

.input-radio {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }
}

.input-list-radio {
  padding: 0px;
  margin: 0px;

  li {
    list-style: none;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.input-radio-input {
  @include visually-hidden;
}

.input-radio-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 10px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #666;
  }

  .input-radio-input:checked + & {
    &:before {
      display: block;
    }
  }

  .input-radio-input:focus + & {
    border-color: #999;
  }
}

.input-radio-label {
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
}

.input-checkbox {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }
}

.input-list-checkbox {
  padding: 0px;
  margin: 0px;

  li {
    list-style: none;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.input-checkbox-input {
  @include visually-hidden;
}

.input-checkbox-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    display: none;
    width: 7px;
    height: 12px;
    border: solid #666;
    border-width: 0 3px 3px 0;
    margin-top: -1px;
  }

  .input-checkbox-input:checked + & {
    &:before {
      display: block;
    }
  }

  .input-checkbox-input:focus + & {
    border-color: #999;
  }
}

.input-checkbox-label {
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
}