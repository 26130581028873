.button {
  font-weight: normal;
  width: 100%;
  padding: 8px 36px;

  @include media-query(xs) {
    width: auto;
  }
}

.button-red {
  background-color: $red;

  &:hover {
    background-color: darken($red, 5%);
  }
}